import React from 'react';
import historyLogo from '../../assets/takeAway/orders/history.svg';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { useNavigate } from 'react-router-dom';

const OrderHeader = ({
  styles,
  orderHistory,
  setOrderHistory,
  tableNumber,
  animationState,
}) => {
  const CheckUrl = window.location.pathname.includes('dineIn/orders');
  const navigate = useNavigate();
  const handleOrderBack = () => {
    if (orderHistory) {
      setOrderHistory(false);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <div className={styles.orderHeader_main} onClick={handleOrderBack}>
        <BackArrow
          className={styles.primaryColorFill + ' ' + styles.backArrow}
        />

        <h3>{!CheckUrl ? 'Orders' : `Table ${ tableNumber }`}</h3>
      </div>
    </>
  );
};
export default OrderHeader;
