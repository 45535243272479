import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import uploadIcon from '../../assets/admin/upload.svg';

const filterCategories = (inputValue, categoryData) => {
  if (inputValue === '') return categoryData;
  else
    return categoryData?.filter((category) =>
      category?.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
};

const promiseOptions = (inputValue, transformCategoryData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterCategories(inputValue, transformCategoryData));
    }, 1000);
  });
};

const transformCategoryDataFunction = (categoryData) => {
  const data = categoryData?.map((item) => ({
    value: item.categoryName?.toLowerCase(),
    label: item.categoryName,
  }));
  return data;
};

const MySelectComponent = ({
  CategoryIndexData,
  onCategoryChange,
  styles,
  onFileChange,
  formData,
  defaultValueVar,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [transformCategoryData, setTransformCategoryData] = useState([]);
  // const [defaultValue, setDefaultValue] = useState(null);
  const [previousCategory, setPreviousCategory] = useState(null);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
    onCategoryChange(selectedOptions.map((option) => option.label));
  };

  useEffect(() => {
    // console.log(CategoryIndexData);
    setTransformCategoryData(transformCategoryDataFunction(CategoryIndexData));
    setPreviousCategory(
      CategoryIndexData?.map((item) => item.categoryName?.toLowerCase())
    );
  }, [CategoryIndexData]);

  useEffect(() => {
    if (defaultValueVar) {
      let data = [];

      if (Array.isArray(defaultValueVar?.categoryNames)) {
        data = defaultValueVar?.categoryNames.map((item) => ({
          value: item?.toLowerCase(),
          label: item,
        }));
      }

      setSelectedValues(data); // Set the default value to selected values
    }
  }, [defaultValueVar]);

  // useEffect(() => {
  //   console.log(defaultValue);
  // }, [defaultValue]);

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base) => ({
      ...base,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px 6px',
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  return (
    <>
      {transformCategoryData ? (
        <>
          <AsyncCreatableSelect
            styles={customStyles}
            isClearable
            isMulti // Enable multi-select
            cacheOptions
            defaultOptions
            loadOptions={(inputValue) =>
              promiseOptions(inputValue, transformCategoryData)
            }
            onChange={handleChange}
            value={selectedValues}
          />
          {selectedValues.map(
            (selectedValue, index) =>
              // if it's not old category
              !previousCategory?.includes(selectedValue.value) && (
                <div key={index} className={styles.upload_image_wrapper}>
                  <h3>Add Image for {selectedValue.label}</h3>
                  <label>
                    <input
                      type="file"
                      name={'categoryImage_' + selectedValue.value}
                      onChange={onFileChange}
                      multiple
                    />
                    <div className={styles.submit_wrapper}>
                      {
                        // formData.categoryFile &&
                        // Object.keys(formData.categoryFile)?.length > 0 ? (
                        //   Object.keys(formData.categoryFile).map((key, index) => (
                        //     <div key={index}>
                        //       {formData.categoryFile[key].name}
                        //     </div>
                        //   ))
                        // )

                        // take all the keys which starts with categoryImage_

                        formData['categoryImage_' + selectedValue.value] &&
                        Object.keys(
                          formData['categoryImage_' + selectedValue.value]
                        )?.length > 0 ? (
                            Object.keys(
                              formData['categoryImage_' + selectedValue.value]
                            ).map((key, index) => (
                              <div key={index}>
                                {
                                  formData[
                                    'categoryImage_' + selectedValue.value
                                  ][key].name
                                }
                              </div>
                            ))
                          ) : (
                            <>
                              <img src={uploadIcon} alt="uploadIcon" />
                              <p>
                              Click Here to upload <br />
                              Upload Good Quality Image
                              </p>
                            </>
                          )
                      }
                    </div>
                  </label>
                </div>
              )
          )}
        </>
      ) : null}
    </>
  );
};

export default MySelectComponent;
