import React from 'react';
import styles from '../../styles/components/cart/continueOrder.module.scss';
import rightArrow from '../../assets/components/common/rightArrow.svg';
import leftArrow from '../../assets/components/common/backArrowWhite.svg';
import { getCompleteOrder } from '../../store/orderSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
const ContinueOrder = ({
  setIndexPage,
  directOrder = false,
  skipUserDetailsOrder = () => {},
}) => {
  const completeOrder = useSelector(getCompleteOrder);
  const handleContinueOrder = () => {
    if (completeOrder.order && completeOrder.order.length > 0) {
      if (directOrder) {
        skipUserDetailsOrder();
      } else {
        setIndexPage('userDetail');
      }
    } else {
      alert('Please add items to cart');
    }
  };
  const navigate = useNavigate();
  const { restaurantId, tableNumber } = useParams();

  return (
    <>
      {completeOrder.order.length > 0 ? (
        <div
          className={styles.ContinueOrder_main}
          onClick={handleContinueOrder}
        >
          <h3>Continue to Place Order</h3>
          <img className={styles.arrowImag} src={rightArrow} alt="rightArrow" />
        </div>
      ) : (
        <div
          className={styles.ContinueOrder_main}
          onClick={() => {
            navigate(`/${ restaurantId }/dineIn/${ tableNumber }`);
          }}
        >
          <img className={styles.arrowImag} src={leftArrow} alt="rightArrow" />

          <h3>Go to Menu</h3>
        </div>
      )}
    </>
  );
};
export default ContinueOrder;
