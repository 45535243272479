import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/categoryIndex/ItemDetails.module.scss';

import backArrowWhite from '../../assets/components/common/backArrowWhite.svg';
import NonVegItem from '../../assets/components/common/NonVegItem.svg';
import VegItem from '../../assets/components/common/VegItem.svg';
import addIcon from '../../assets/components/common/addIcon.svg';
import cartIcon from '../../assets/components/footer/openCart.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addToOrder } from '../../store/orderSlice';
import { getLowestPrice } from '../../utils/generalFunction';
import { useNavigate, useParams } from 'react-router-dom';

const Checkbox = ({ item, checkBoxArray, setCheckBoxArray }) => {
  const handleChange = () => {
    const updatedArray = checkBoxArray.map((mapItem) =>
      mapItem.name === item.name
        ? { ...mapItem, checked: !mapItem.checked }
        : mapItem
    );
    setCheckBoxArray(updatedArray);
  };

  return (
    <div className={styles.item_details_checkBox_container}>
      <span>{item.name}</span>
      <span>
        AUD {item.price}
        <input type="checkbox" checked={item.checked} onChange={handleChange} />
      </span>
    </div>
  );
};

const ItemDetails = ({ setItemPage, item, productImg }) => {
  const orders = useSelector((state) => state.order);

  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [notify, setNotify] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const { tableNumber } = useParams();
  useEffect(() => {
    if (item) {
      // Set default customizable items
      const temp = item?.customizableItems?.map((addon) => ({
        name: addon.name,
        isVeg: addon.isVeg,
        price: addon.price,
        checked: false,
      }));
      setCheckBoxArray(temp || []);

      // Set the lowest price item by default
      const lowestPriceOption = item?.prices?.reduce((prev, curr) =>
        prev.price < curr.price ? prev : curr
      );
      setSelectedOption(lowestPriceOption);
    }
  }, [item]);

  const handleOptionChange = (option) => {
    setError(false);
    setSelectedOption(option);
  };

  const addToCart = () => {
    if (!selectedOption) {
      setError(true);
      return;
    }

    // Gather selected add-ons
    const selectedAddons = checkBoxArray.filter((addon) => addon.checked);

    // Prepare the order data
    const orderData = {
      // ...item,\
      dishId: item._id,
      dishName: item.dishName,
      portionSize: selectedOption.portionSize,
      price: selectedOption.price,
      quantity: quantity,
      customizedItem: selectedAddons,
      gstTax: item.gstTax,
    };

    console.log('Order Data:', orderData);

    // Remove unnecessary properties if any
    if (orderData.hasOwnProperty('prices')) {
      delete orderData.prices;
    }

    // Dispatch the order to Redux store
    dispatch(addToOrder(orderData));
    // setNotify(true);
    // resetSelections();
  };

  const resetSelections = () => {
    console.log('Resetting selections');
    setSelectedOption(null);
    setCheckBoxArray((prev) =>
      prev.map((item) => ({ ...item, checked: false }))
    );
    setQuantity(1);
    // setTimeout(() => setNotify(false), 3000);
  };

  return (
    <div className={styles.ItemDetails_main}>
      <div className={styles.img_wrapper}>
        <img
          className={styles.backArrowWhite}
          onClick={() => setItemPage(false)}
          src={backArrowWhite}
          alt="Back"
        />
        <img className={styles.image_icon} src={productImg} alt="Product" />
      </div>
      <div className={styles.ItemDetails_wrapper}>
        <div className={styles.horizontal_bar}></div>
        <h2>Customise as per your taste</h2>
        <div className={styles.nameAndPrice}>
          <img
            src={item.dishType === 'VEG' ? VegItem : NonVegItem}
            alt="Type"
          />
          <span>{item.dishName}</span>
        </div>
        <p>{item.description}</p>

        {/* Add-ons Section */}
        {checkBoxArray.length > 0 && (
          <>
            <div className={styles.horizontal_rulling}></div>
            <h2>Add-ons</h2>
            <div className={styles.item_details_checkBox_container_wrapper}>
              {checkBoxArray.map((addon) => (
                <Checkbox
                  key={addon.name}
                  item={addon}
                  checkBoxArray={checkBoxArray}
                  setCheckBoxArray={setCheckBoxArray}
                />
              ))}
            </div>
          </>
        )}

        <div className={styles.horizontal_rulling}></div>
        <div className={styles.price_container_item_details}>
          <h2>Select Quantity</h2>
          {error && (
            <p style={{ color: 'red' }}>Required * Select any one option</p>
          )}
          <div className={styles.price_container_item_details_wrapper}>
            {item?.prices?.map((option, index) => (
              <div key={index}>
                <label>
                  {option.portionSize} - AUD{option.price}
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => handleOptionChange(option)}
                  />
                  {/* <span
                    className={
                      option === selectedOption
                        ? styles.circle_wrapper_active
                        : styles.circle_wrapper
                    }
                  ></span> */}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Cart Section */}
      <div className={styles.item_cart_bar}>
        <div className={styles.quantity_button_wrapper}>
          <button onClick={() => setQuantity((cur) => (cur > 1 ? cur - 1 : 1))}>
            -
          </button>
          <span>{quantity < 10 ? `0${ quantity }` : quantity}</span>
          <button onClick={() => setQuantity((cur) => cur + 1)}>
            <img src={addIcon} alt="Add" />
          </button>
        </div>
        <button onClick={addToCart} className={styles.addToCart_button}>
          Add to Cart | AUD
          {selectedOption
            ? selectedOption.price * quantity
            : getLowestPrice(item.prices) * quantity}
        </button>
      </div>

      {orders?.order?.length > 0 && (
        // <div className={styles.add_new_item}>
        //   {/* {quantity}  */}
        //   Item added to cart
        // </div>
        <div
          className={styles.cart_footer_wrapper}
          onClick={() => {
            navigate(`/${ restaurantId }/dineIn/${ tableNumber }/cart`);
          }}
        >
          <span className={styles.cartNumber}>
            {orders?.order ? orders?.order?.length : ''}
          </span>
          <img className={styles.smallIcons} src={cartIcon} alt="cartIcon" />
        </div>
      )}
    </div>
  );
};

export default ItemDetails;
