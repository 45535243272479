import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItemCard from './OrderItemCard';
import styles from '../../styles/components/order.module.scss';

const OrderPage = ({
  orderState,
  isEmployee,
  q_restaurantId,
  q_tableNumber,
  setTable = () => {},
  isUser = true,
}) => {
  const navigate = useNavigate();
  const { restaurantId, tableNumber } = useParams();

  // console.log(orderState);

  return (
    <div className={styles.OrderPage_wrapper}>
      <div>
        {orderState?.map((item, index) => {
          return <OrderItemCard orderList={item} key={index} isUser={isUser} />;
        })}
      </div>

      <div className={styles.navigation_button}>
        <button
          onClick={() => {
            if (isEmployee) {
              setTable(null);
            } else {
              navigate(`/${ restaurantId }/dineIn/${ tableNumber }`);
            }
          }}
        >
          Return Home
        </button>
        <button
          onClick={() =>
            navigate(
              `/${ restaurantId || q_restaurantId }/dineIn/${
                tableNumber || q_tableNumber
              }`
            )
          }
        >
          Add more items
        </button>
      </div>
    </div>
  );
};

export default OrderPage;
