import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/admin/adminSideBar.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/admin/closeIcon.svg';
// import logoIcon from '../../assets/components/logo/Logo.svg';
import { ReactComponent as CategoryMenu } from '../../assets/components/logo/categoryMenuIcon.svg';
import { ReactComponent as TableOrders } from '../../assets/components/logo/tableOrders.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Book } from '../../assets/components/common/book.svg';
import { ReactComponent as Clock } from '../../assets/components/common/clock.svg';
import { ReactComponent as Group } from '../../assets/components/common/group.svg';
import { useSelector } from 'react-redux';

const AdminSideBar = ({ setSideBar, sideBar }) => {
  const [open, setOpen] = useState(sideBar);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const restaurantPublicInfo = useSelector(
    (state) => state.restaurantPublicInfo
  );
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setSideBar(open);
    }, 300);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [open, setSideBar]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const navigateAdminSide = (name) => {
    navigate(`/${ name }`);
  };

  const navigationMenu = [
    {
      link: 'admin/menu',
      icon: CategoryMenu,
      iconStyle: styles.primaryColorStroke,
      title: 'Food Category Menu',
    },
    {
      link: 'admin/tables',
      icon: TableOrders,
      iconStyle: styles.primaryColorFill,
      title: 'Table Orders',
    },
    {
      link: 'admin/orderList',
      icon: Book,
      iconStyle: styles.primaryColorStroke,
      title: 'Orders List',
    },
    {
      link: 'admin/employee',
      icon: Group,
      iconStyle: styles.primaryColorFill,
      title: 'Employee Management',
    },
    // {
    //   link: 'subscription',
    //   icon: Clock,
    //   iconStyle: styles.primaryColorFill,
    //   title: 'Subscription',
    // },
  ];

  return (
    <>
      <div
        className={`${ styles.AdminSideBar_wrapper } ${
          open
            ? styles.AdminSideBar_wrapper_fadeIn
            : styles.AdminSideBar_wrapper_fadeOut
        }`}
        onClick={(e) => {
          handleToggle();
        }}
      >
        <div
          className={`${ styles.AdminSideBar_main } ${
            open ? styles.open_Admin_sideBar : styles.close_admin_sideBar
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <header>
            <CloseIcon
              className={styles.primaryColorStroke + ' ' + styles.cursorPointer}
              onClick={handleToggle}
            />
            {restaurantPublicInfo?.restaurantLogoBlobUrl && (
              <img
                src={restaurantPublicInfo?.restaurantLogoBlobUrl}
                className={styles.logo}
                alt="logo"
              />
            )}
          </header>
          <ul>
            {navigationMenu.map((item, index) => (
              <li key={index} onClick={() => navigateAdminSide(item.link)}>
                <item.icon className={item.iconStyle} />
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminSideBar;
