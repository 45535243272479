import React, { useState } from 'react';
import styles from '../../styles/takeAway/orderItemCard.module.scss';
import infoIcon from '../../assets/components/common/infoIcon.svg';
import { ReactComponent as ColorStar } from '../../assets/components/common/colorStar.svg';
import { ReactComponent as EmptyStar } from '../../assets/components/common/emptyStar.svg';
import vegIcon from '../../assets/components/common/VegItem.svg';
import noVegIcon from '../../assets/components/common/NonVegItem.svg';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { ReactComponent as ActiveCooking } from '../../assets/chefIcon/ActiveCooking.svg';
import { ReactComponent as Cooking } from '../../assets/chefIcon/Cooking.svg';
import { ReactComponent as DishIcon } from '../../assets/chefIcon/DishIcon.svg';
import { ReactComponent as ActiveDishIcon } from '../../assets/chefIcon/ActiveDishIcon.svg';
import serving from '../../assets/waitersIcon/serving.svg';
import { ReactComponent as ActiveServing } from '../../assets/waitersIcon/activeServing.svg';

const OrderItem = ({
  styles,
  item,
  history,
  waiter,
  waiterIcon,
  orderList,
}) => {
  console.log(item.customizedItem);
  console.log(orderList);
  const [list, setList] = useState(false);
  return (
    <>
      <div className={styles.OrderItem_wrapper_div}>
        <div className={styles.order_header}>
          <span>
            <img src={item.isVeg ? vegIcon : noVegIcon} alt="vegOrNonvegIcon" />{' '}
            {item.name} x {item.quantity}
          </span>
          {item?.price && <strong>AUD {item.price}</strong>}
          {item?.status && !history && !waiter ? (
            <div className={styles.cookingOrCooked_wrapper}>
              {/* <img src={item.status === 'cooking' ? activeCooking : cooking} alt='cookingOrCooked'/>
            <img src={item.status === 'cooked' ? activeDishIcon : dishIcon} alt='cookingOrCooked'/> */}

              {item.status === 'cooking' ? (
                <ActiveCooking className={styles.primaryColor} />
              ) : (
                <Cooking className={styles.primaryColor} />
              )}
              {item.status === 'cooked' ? (
                <ActiveDishIcon className={styles.primaryColor} />
              ) : (
                <DishIcon className={styles.primaryColor} />
              )}
            </div>
          ) : (
            item?.status &&
            waiter && (
              <>
                <img src={serving} alt="serving" />
              </>
            )
          )}
          {waiterIcon ? (
            // <img src={activeServing} alt="activeServing" />
            <ActiveServing className={styles.primaryColorFill} />
          ) : null}
        </div>
        <span className={styles.customised_Items_wrapper}>
          <small>GST {item?.gstTax}%</small>
          {/* <h5>Customised Items</h5>{' '} */}
          {/* <BackArrow
            onClick={() => setList((cur) => !cur)}
            className={styles.primaryColorFill}
          /> */}
        </span>
        <ul className={styles.customised_Items_wrapper_list}>
          {list
            ? item?.customizedItem
              .filter((customizedItem) => customizedItem.checked)
              .map((list, index) => (
                <li key={index}>
                  <img src={vegIcon} alt="vegIcon" />
                  {list.name}
                </li>
              ))
            : null}
        </ul>
        <div className={styles.horizontal_rulling}></div>
      </div>
    </>
  );
};

const BillingItemCard = ({ orderList, history, waiter, waiterIcon }) => {
  // console.log(orderList);

  console.log(orderList);
  const [star, setStar] = useState(3);
  function getFormattedDateTime(item) {
    console.log(item);
    if (!item?.createdAt) return null;
    console.log('her');
    const updatedAt = new Date(item.createdAt);
    const options = { hour12: true, hour: 'numeric', minute: '2-digit' };
    const formattedDate = updatedAt.toLocaleDateString(); // Get the formatted date
    const formattedTime = updatedAt.toLocaleTimeString(undefined, options); // Get the formatted time with AM/PM

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  const [currentStatus, setCurrentStatus] = useState('Served');

  const statusStages = ['Placed', 'Preparing', 'Ready', 'Served'];

  const getProgressWidth = (status) => {
    const index = statusStages.indexOf(status);
    if (index === -1) return '0%';
    return `${ (index / (statusStages.length - 1)) * 100 }%`;
  };

  const formattedDateTime = getFormattedDateTime(orderList);
  return (
    <>
      <div className={styles.orderPage_card_wrapper}>
        <header className={styles.billingCard_header}>
          Table Order Summary
        </header>

        {orderList?.items?.map((item, index) => (
          <OrderItem
            styles={styles}
            key={index}
            item={item}
            history={history}
            waiter={waiter}
            waiterIcon={waiterIcon}
            orderList={orderList}
          />
        ))}

        <div className={styles.details}>
          <div className={styles.time}>
            <span>{formattedDateTime?.date}</span>
            <span>{formattedDateTime?.time}</span>
          </div>
          <div className={styles.price}>
            {orderList?.totalPrice && (
              <>
                {/* <img src={infoIcon} alt="infoIcon" /> */}
                <strong> AUD {orderList?.totalPrice}</strong>
              </>
            )}
          </div>
        </div>
        <small className={styles.total_price_toolpit}>
          Includes all taxes and charges
        </small>
      </div>
    </>
  );
};
export default BillingItemCard;
