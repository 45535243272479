import React, { useState, useEffect, useRef } from 'react';
import cutlery from '../../assets/components/sideMenuButton/cutlery.svg';
import styles from '../../styles/sideMenuButton/sideMenuButton.module.scss';
import { useNavigate, useParams } from 'react-router-dom';

const SideMenuButton = ({ menu, setCategory, isAdmin = false }) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const resturauntId = useParams()?.restaurantId;
  const tableNumber = useParams()?.tableNumber;

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const navigate = useNavigate();

  return (
    <div
      ref={wrapperRef}
      className={`${ styles.SideMenuButton_wrapper } ${
        show ? styles.SideMenuButton_wrapper_active : ''
      }`}
    >
      <header onClick={() => setShow((cur) => !cur)}>
        <img src={cutlery} alt="cutlery" />
        <span>Menu</span>
      </header>
      {show && (
        <ul>
          {menu && menu.length > 0 ? (
            menu.map((item, index) => (
              <li
                onClick={() => {
                  setShow(false);
                  setCategory(item);
                  if (isAdmin) {
                    navigate(`/admin/menu/${ item.categoryName }`);
                  } else {
                    // navigate(`${item.categoryName}`);
                    if (tableNumber) {
                      navigate(
                        `/${ resturauntId }/dineIn/${ tableNumber }/${ item.categoryName }`
                      );
                    }
                  }
                }}
                key={index}
              >
                {item.categoryName}
              </li>
            ))
          ) : (
            <div className={styles.emptySideMenuBtn}>No Items added yet!</div>
          )}
        </ul>
      )}
    </div>
  );
};

export default SideMenuButton;
