import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/components/common/closeIcon.svg';

const AddItemPopper = ({ setAddItemOpener, mainRef, styles, setPopper }) => {
  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.classList.add(styles.overflowNone);
    }
    return () => {
      if (mainRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mainRef.current.classList.remove(styles.overflowNone); // Remove class when component unmounts
      }
    };
  }, [mainRef, styles.overflowNone]);

  const handleCancel = (e) => {
    e.preventDefault();
    setAddItemOpener(false);
  };

  return (
    <div className={`${ styles.addItem_oppner_wrapper }`}>
      <div className={styles.addItem_oppner}>
        <header className={styles.header_add_item}>
          {/* <img
            onClick={() => setPopper(false)}
            src={closeIcon}
            alt="close" />*/}
          <CloseIcon
            className={styles.primaryColorStroke + ' ' + styles.cursorPointer}
            onClick={() => setPopper(false)}
          />
        </header>
        <p>Are you sure you want to exit before saving?</p>
        <div className={styles.button_wrapper}>
          <button onClick={handleCancel}>Yes</button>
          <button onClick={() => setPopper(false)}>No</button>
          {/* <button onClick={handleCancel}>Save as Draft</button> */}
        </div>
      </div>
    </div>
  );
};

export default AddItemPopper;
