import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/categoryIndex/categoryItem.module.scss';
import { ReactComponent as MoreArrow } from '../../assets/takeAway/categoryIndex/moreArrow.svg';
import { ReactComponent as EntypeIcon } from '../../assets/components/common/entypo.svg';
import deleteIcon from '../../assets/components/common/delete.svg';
import { ReactComponent as Line } from '../../assets/components/common/line.svg';
import { ReactComponent as Edit } from '../../assets/components/common/edit.svg';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { useDispatch } from 'react-redux';
import { deleteCategoryReducer } from '../../store/categorySlice';
import { ReactComponent as CloseIcon } from '../../assets/admin/closeIcon.svg';
import { getFileData } from '../../api_calls/getFileData';
import { getLocalStorageItem } from '../../utils/generalFunction';
import NoCategoryImage from '../../assets/takeAway/categoryIndex/NoCategoryImage.svg';
import { useNavigate } from 'react-router-dom';

const DeletePopper = ({ setPoper, item }) => {
  const dispatch = useDispatch();

  const deleteCategory = async (e) => {
    e.stopPropagation();
    setPoper(true);
    const response = await generateGetCall({
      apiPath: `${ API_ENDPOINT_NAME.TEST }?categoryId=${ item._id }`,
    });
    if (response.success) {
      dispatch(deleteCategoryReducer(response.response.data.deleteCategoryId));
      setPoper(false);
    }
  };

  return (
    <>
      <div className={styles.opening_popper}>
        <div className={styles.innerPopper}>
          <div className={styles.innerPopper_padding}>
            {/* <img
              onClick={(e) => setPoper(false)}
              src={closeIcon}
              alt="closeIcon"
            /> */}

            <CloseIcon
              className={styles.primaryColorStroke + ' ' + styles.cursorPointer}
              onClick={(e) => setPoper(false)}
            />
            <p>
              Are you sure you want to delete this category ? <br />
              (It will delete food items as well)
            </p>
            <div className={styles.button_wrapper_edit}>
              <button onClick={(e) => setPoper(false)}>Cancel</button>
              <button onClick={(e) => deleteCategory(e)}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// returns the URL of the profile photo
const getCategoryURL = async (
  restaurantId,
  categoryId,
  assetDetails,
  setCategoryImageURL
) => {
  // console.log('getCategoryURL assetDetails', assetDetails);

  setCategoryImageURL([]);
  assetDetails?.forEach(async (item) => {
    let responseBlob = await getFileData({
      apiPath: API_ENDPOINT_NAME.RESTAURANT_PUBLIC_IMAGE,
      data: {
        restaurantId: restaurantId,
        ...item,
        id: categoryId,
        assetType: 'CATEGORY',
      },
    });

    if (responseBlob) {
      let url = URL.createObjectURL(responseBlob.fileBlob);
      // allImagesURL.push(url);
      console.log('url', url);
      // setCategoryImageURL((prev) => [...prev, url]);
      // if same URL is pusing again then it will not push
      setCategoryImageURL((prev) => {
        if (!prev.includes(url)) {
          return [...prev, url];
        }
        return prev;
      });
    }
  });
};

const CategoryItem = ({ item, setCategory, isadmin, setEditCategory }) => {
  const [menu, setMenu] = useState(false);
  const [poper, setPoper] = useState(false);

  const handleMenuOptions = (e) => {
    e.stopPropagation();
    setMenu((menu) => !menu);
  };

  const deleteCategory = async (e) => {
    e.stopPropagation();
    setPoper(true);
    // const response = await generateGetCall({ apiPath: `${ API_ENDPOINT_NAME.TEST }?categoryId=${ item._id }` });
    // if (response.success) {
    //   dispatch(deleteCategoryReducer(response.response.data.deleteCategoryId));
    // }
  };

  const EditCategory = (e) => {
    e.stopPropagation();
    setEditCategory({ _id: item._id, categoryName: item.categoryName });
  };

  const [categoryImageURL, setCategoryImageURL] = useState([]);

  useEffect(() => {
    // console.log('item.categoryImages', item.categoryImages, item);
    if (item.categoryImages) {
      getCategoryURL(
        getLocalStorageItem('restaurantId'),
        item._id,
        item.categoryImages,
        setCategoryImageURL
      );
    }
  }, [item.categoryImage]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.categoryItem_main_item}
        onClick={() => {
          setCategory(item);
          if (isadmin) {
            navigate(`/admin/menu/${ item.categoryName }`);
          } else {
            navigate(`${ item.categoryName }`);
          }
        }}
        onMouseLeave={() => setMenu(false)}
      >
        <div className={styles.categoryItem_details}>
          <span>
            {categoryImageURL.length > 0 ? (
              categoryImageURL.map((url, index) => (
                <img
                  src={url || NoCategoryImage}
                  alt={item.name}
                  loading="lazy"
                  key={index}
                />
              ))
            ) : (
              <img src={NoCategoryImage} alt={item.name} loading="lazy" />
            )}
          </span>
          {item.categoryName}
        </div>
        <div className={styles.categoryItem_menu_list}>
          {isadmin ? (
            <>
              <EntypeIcon
                className={
                  styles.primaryColorFill +
                  ' ' +
                  styles.cursorPointer +
                  ' ' +
                  styles.handleMoreOptions
                }
                onClick={handleMenuOptions}
              />
              {menu && (
                <>
                  <div className={styles.categoryItem_menu_list_ul}>
                    <Edit
                      className={
                        styles.primaryColorFill + ' ' + styles.cursorPointer
                      }
                      onClick={EditCategory}
                    />

                    <Line className={styles.primaryColorStroke} />
                    <img
                      onClick={deleteCategory}
                      src={deleteIcon}
                      alt="delete"
                      className={styles.cursorPointer}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <MoreArrow className={styles.primaryColorStroke} />
          )}
        </div>
      </div>
      {poper ? <DeletePopper setPoper={setPoper} item={item} /> : null}
    </>
  );
};

export default CategoryItem;
