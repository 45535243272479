import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../styles/takeAway/takeAwayIndex.module.scss';
import HeaderLogo from '../../components/Header/Header.js';
import Footer from '../../components/footer/Footer.js';
import SearchComponent from '../../components/searchComponent/SearchComponent.js';
import CategoryIndex from '../../components/category/CategoryIndex.js';
// import foodIcon from '../../assets/takeAway/categoryIndex/foodIcon.svg';
// import fastFoodIcon from '../../assets/takeAway/categoryIndex/fastFoodIcon.svg';
// import drinks from '../../assets/takeAway/categoryIndex/drinks.svg';
// import deserts from '../../assets/takeAway/categoryIndex/deserts.svg';
import ItemMenuIndex from '../../components/category/ItemMenuIndex.js';
import SideMenuButton from '../../components/sideMenuButton/SideMenuButton.js';
import { generateGetCall } from '../../api_calls/generateGetCall.js';
import { API_ENDPOINT_NAME } from '../../utils/constants.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategories,
  storeAllCategory,
} from '../../store/categorySlice.js';
import { useParams } from 'react-router-dom';
import AnimationWrapper from '../../components/common/AnimationWrapper.js';

const TakeAwayIndex = () => {
  const [searchInput, setSearchInput] = useState(null);
  const [category, setCategory] = useState(null);
  const [categoryIndexData, setCategoryIndexData] = useState();
  const categoryData = useSelector(getAllCategories);
  const dispatch = useDispatch();
  const { restaurantID } = useParams();

  const fetchData = useCallback(async () => {
    try {
      const response = await generateGetCall({
        apiPath: `${ API_ENDPOINT_NAME.GET_CATEGORY }?restaurantId=${ restaurantID }`,
      });
      if (response.success) {
        setCategoryIndexData(response.response.data.categoriesWithDishes);
        dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
      } else {
        console.error('API Call succeeded but returned an error:', response);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  }, []); // Since dispatch function from Redux is stable and doesn’t change, it is safe to include here.

  useEffect(() => {
    if (categoryData?.length === 0) {
      fetchData();
    } else {
      setCategoryIndexData(categoryData);
    }
  }, []);

  return (
    <>
      <div className={styles.TakeAwayIndex_file}>
        {!category ? <HeaderLogo styles={styles} /> : null}
        <div className={styles.TakeAwayIndex_main}>
          {!category ? (
            <SearchComponent setSearchInput={setSearchInput} />
          ) : null}
          {!category ? (
            categoryIndexData ? (
              <CategoryIndex
                categoryIndexData={categoryIndexData}
                setCategory={setCategory}
              />
            ) : (
              <AnimationWrapper />
            )
          ) : (
            <ItemMenuIndex
              category={category}
              setCategory={setCategory}
              categoryList={categoryIndexData}
              setcategoryList={setCategoryIndexData}
            />
          )}
        </div>
        <SideMenuButton menu={categoryIndexData} setCategory={setCategory} />
        <Footer />
      </div>
    </>
  );
};

export default TakeAwayIndex;
