import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/cart/userDetails.module.scss';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { ReactComponent as UserIcon } from '../../assets/components/cart/userIcon.svg';
import backArrowWhite from '../../assets/components/common/backArrowWhite.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToPlacedOrder,
  getCompleteOrder,
  resetOrder,
} from '../../store/orderSlice';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generatePostCall } from '../../api_calls/generatePostCall';
import CountryCodeDropDown from '../../views/login/CountryCodeDropDown';
import { setLocalStorageItem } from '../../utils/generalFunction';

const inputlabel = [
  {
    name: 'userName',
    label: 'Name',
    type: 'text',
    placeholder: 'Name',
    isRequired: true,
  },
  {
    name: 'mobileNumber',
    label: 'Mobile Number',
    type: 'number',
    placeholder: '70********',
    isRequired: true,
  },
  {
    name: 'email',
    label: 'Email address',
    type: 'email',
    placeholder: 'info@gmail.com',
    isRequired: true,
  },
];

const InputField = ({
  item,
  setUserstate,
  countryCodes,
  setSelectedCountryCode,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserstate((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  return (
    <label>
      {item.label}
      <span>
        {' '}
        {item.name === 'mobileNumber' && (
          <CountryCodeDropDown
            options={countryCodes}
            setSelectedCountryCode={setSelectedCountryCode}
          />
        )}
        <input
          onChange={handleChange}
          type={item.text}
          placeholder={item.placeholder}
          name={item.name}
        />
      </span>
    </label>
  );
};

const UserDetails = ({ setIndexPage, reservation }) => {
  const completeOrder = useSelector(getCompleteOrder);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tableNumber, restaurantId } = useParams();
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [userState, setUserstate] = useState({
    userName: '',
    mobileNumber: '',
    email: '',
    selectedCountryCode, //default value
  });

  // if data is not provided then give alert that data is required or skip the user details

  const handleContinueOrder = async () => {
    if (
      userState.name === '' ||
      userState.mobileNumber === '' ||
      userState.email === ''
    ) {
      alert('Please fill all the fields, or skip the user details');
      return;
    }

    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.CREATE_CUSTOMER,
      data: userState,
    });
    if (response.success) {
      completeOrder.tableNumber = tableNumber;
      completeOrder.userID = response.response.data.userID;
      const responseTwo = await generatePostCall({
        apiPath: `${ API_ENDPOINT_NAME.PLACE_ORDER }?restaurantId=${ restaurantId }&tableId=${ tableNumber }`,
        data: completeOrder,
      });
      if (responseTwo.success) {
        dispatch(addToPlacedOrder(response.response.data.data));
        dispatch(resetOrder());
        navigate(
          `/${ restaurantId }/${
            window.location.href.includes('dineIn') ? 'dineIn' : 'takeaway'
          }/${ tableNumber }/orders`,
          { state: { animation: true } }
        );
      }
    }
  };

  const skipUserDetailsOrder = async () => {
    if (window.location.href.includes('dineIn') === true) {
      console.log(completeOrder);
      completeOrder.tableNumber = tableNumber;
      const response = await generatePostCall({
        apiPath: `${ API_ENDPOINT_NAME.PLACE_ORDER }?restaurantId=${ restaurantId }&tableId=${ tableNumber }`,
        data: completeOrder,
      });
      if (response.success) {
        dispatch(addToPlacedOrder(response.response.data.data));
        dispatch(resetOrder());
        navigate(`/${ restaurantId }/dineIn/${ tableNumber }/orders`, {
          state: { animation: true },
        });
      }
    }
  };

  const setAuthToken = async (response) => {
    try {
      const authorizationHeader = response.headers.authorization;
      const tokenWithoutBearer = authorizationHeader.split(' ')[1];
      setLocalStorageItem('accessToken', tokenWithoutBearer);
    } catch (err) {
      console.log('Error - setAuthToken');
    }
  };

  const handleUserReservation = async () => {
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.CREATE_CUSTOMER,
      data: userState,
    });
    if (response.success) {
      setAuthToken(response.response);
      setIndexPage(true);
    }
  };

  const fetchCountryCode = async () => {
    try {
      const response = await fetch(API_ENDPOINT_NAME.COUNTRY_PHONE_CODE);
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
      const data = await response.json();
      setCountryCodes(data);
      // Handle the response data here
    } catch (error) {
      console.error('Error fetching country codes:', error);
      // Handle error
    }
  };
  useEffect(() => {
    fetchCountryCode();
  }, []);

  return (
    <>
      <div className={styles.main_userDetials_con}>
        <header>
          <BackArrow
            onClick={() =>
              reservation ? navigate(`/${ restaurantId }`) : setIndexPage(null)
            }
            className={styles.primaryColorFill}
          />

          <span>User Details</span>
          {window.location.href.includes('dineIn') ? (
            <button onClick={skipUserDetailsOrder}>Skip</button>
          ) : null}
        </header>
        <div className={styles.main_userDetails_wrapper}>
          {inputlabel.map((item, index) => (
            <InputField
              setSelectedCountryCode={setSelectedCountryCode}
              countryCodes={countryCodes}
              key={index}
              item={item}
              setUserstate={setUserstate}
            />
          ))}
          {/* <img src={userIcon} alt='userIcon' /> */}
          {/* <UserIcon className={styles.primaryColor} /> */}
          {!reservation ? (
            <div className={styles.continue_order_wrapper}>
              <button onClick={handleContinueOrder}>
                Continue to Place Order
                <img src={backArrowWhite} alt="backArrowWhite" />{' '}
              </button>
            </div>
          ) : (
            <div className={styles.continue_order_wrapper}>
              <button onClick={handleUserReservation}>
                Add reservation
                <img src={backArrowWhite} alt="backArrowWhite" />{' '}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
