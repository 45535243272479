import React, { useState } from 'react';
import styles from '../../styles/takeAway/orderHistoryPage.module.scss';
import noOrderIcon from '../../assets/takeAway/orders/noOrder.svg';
import vegIcon from '../../assets/components/common/VegItem.svg';
import noVegIcon from '../../assets/components/common/NonVegItem.svg';

const OrderCard = ({ item }) => {
  const [open, setOpen] = useState();
  return (
    <>
      <div className={styles.OrderHistoryPage_order_card}>
        <div className={styles.oneSide}>
          <header>
            {' '}
            <img
              src={item.order.list[0].isVeg ? vegIcon : noVegIcon}
              alt="vegOrNonVeg"
            ></img>
            <strong>
              {item.order.list[0].name} {`(${ item.order.list[0].quantity } Qty)`}
            </strong>
          </header>
          {open ? (
            <>
              <span
                className={styles.list_alternative_span}
                onClick={() => setOpen(false)}
              >
                Previous Order List
              </span>
              <ul>
                {item.order.list.slice(1).map((element, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <img
                          src={element.isVeg ? vegIcon : noVegIcon}
                          alt="vegOrNonVeg"
                        ></img>{' '}
                        {element.name}
                      </span>
                      <span>AUD {element.price}</span>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <div className={styles.list_alternative}>
              {item.order.list.length > 1 ? (
                <span onClick={() => setOpen(true)}>{`+ ${
                  item.order.list.length - 1
                } More items`}</span>
              ) : null}
            </div>
          )}
          <p>AUD {item.totalPrice}</p>
        </div>
        <div className={styles.twoSide}>
          <ul>
            <li>{item.date}</li>
            <li>{item.time}</li>
          </ul>
          <button>Order Again</button>
        </div>
      </div>
      <div className={styles.horizontal_rulling}></div>
    </>
  );
};

const prevOrder = [
  {
    order: {
      list: [
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: false,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: false,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: true,
        },
      ],
    },
    date: '12/02/2023',
    time: '10:29am',
    totalPrice: 5000,
  },
  {
    order: {
      list: [
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: false,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: true,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: true,
        },
      ],
    },
    date: '12/02/2023',
    time: '10:29am',
    totalPrice: 5000,
  },
  {
    order: {
      list: [
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: false,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: true,
        },
        {
          name: 'Chicken Biryani',
          quantity: 2,
          price: 200,
          isVeg: true,
        },
      ],
    },
    date: '12/02/2023',
    time: '10:29am',
    totalPrice: 5000,
  },
];

const OrderHistoryPage = () => {
  const [orderHistory, setOrderHistory] = useState(prevOrder);
  return (
    <>
      {orderHistory ? (
        <div className={styles.OrderHistoryPage_main_fill}>
          {orderHistory ? (
            <div className={styles.horizontal_rulling}></div>
          ) : null}
          {orderHistory.map((item, index) => (
            <OrderCard key={index} item={item} />
          ))}
        </div>
      ) : (
        <div className={styles.OrderHistoryPage_main}>
          <img src={noOrderIcon} alt="noOrderIcon" />
          <h4>No orders are placed yet!</h4>
        </div>
      )}
    </>
  );
};
export default OrderHistoryPage;
