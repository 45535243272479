import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateGetCall } from './api_calls/generateGetCall';
import { API_ENDPOINT_NAME } from './utils/constants';

function RedirectTo() {
  let qrId = useParams().qrId;
  const navigate = useNavigate();
  const [Error, setError] = React.useState(null);

  const redirectTo = async (qrId) => {
    try {
      const response = await generateGetCall({
        apiPath: API_ENDPOINT_NAME.RESTAURANT_TABLE_MAPPING_FROM_QR_ID,
        pathId: qrId,
      });
      console.log(response.response.data.data);
      let restaurantId = response.response.data.data.restaurant_id;
      let tableId = response.response.data.data.table_id;

      if (restaurantId && tableId) {
        console.log(restaurantId, tableId);
        navigate(`/${ restaurantId }/dineIn/${ tableId }`);
      }
    } catch (err) {
      console.log(err);
      setError('Invalid QR Code');
    }
  };

  useEffect(() => {
    redirectTo(qrId);
  }, [qrId]);

  return <div>{Error ? 'Invalid QR Code' : 'Redirecting ...'}</div>;
}

export default RedirectTo;
