import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/takeAway/ordersIndex.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import OrderPage from './OrderPage.js';
import OrderHeader from './OrderHeader.js';
import OrderHistoryPage from './OrderHistoryPage.js';
import OrderAnimationOne from './orderAnimation/OrderAnimationOne.js';
import OrderAnimationTwo from './orderAnimation/OrderAnimationTwo.js';
import OrderAnimationThree from './orderAnimation/OrderAnimationThree.js';
import OrderAnimationFour from './orderAnimation/OrderAnimationFour.js';
import { generateGetCall } from '../../api_calls/generateGetCall.js';
import { API_ENDPOINT_NAME, SOCKET_HOST } from '../../utils/constants.js';
import BillingPage from './BillingPage.js';
import { getLocalStorageItem } from '../../utils/generalFunction.js';
import { io } from 'socket.io-client';

const AnimationComponent = ({ setAnimationState }) => {
  const [selectedAnimation, setSelectedAnimation] = useState(null);

  useEffect(() => {
    const animations = [
      <OrderAnimationOne key={1} />,
      <OrderAnimationTwo key={2} />,
      <OrderAnimationThree key={3} />,
      <OrderAnimationFour key={4} />,
    ];

    const randomIndex = Math.floor(Math.random() * animations.length);
    setSelectedAnimation(animations[randomIndex]);

    const timeoutId = setTimeout(() => {
      setAnimationState(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId); // Clear timeout on unmount
    };
  }, [setAnimationState]);

  return (
    <div className={styles.order_index_animation_wrapper}>
      {selectedAnimation}
      <div className={styles.progressBar_wrapper}>
        <div></div>
      </div>
      <span>Hold up page loading...</span>
    </div>
  );
};

const OrdersIndex = ({
  isEmployee,
  setTable = () => {},
  q_restaurantId,
  q_tableNumber,
  billingDetails = false,
  isUser = true,
}) => {
  const location = useLocation();
  const initialState = location.state;
  const [animationState, setAnimationState] = useState(initialState?.animation);
  const [orderHistory, setOrderHistory] = useState(false);
  const [orderState, setOrderState] = useState();
  const socket = useRef(null);
  const host = SOCKET_HOST;
  const { tableNumber, restaurantId } = useParams();

  // Socket initialization and event handling

  // const updateOrderStatus = (orderId, status) => {
  //   setOrderState((prevOrders) =>
  //     prevOrders.map((order) =>
  //       order._id === orderId ? { ...order, status } : order
  //     )
  //   );
  // };

  const updateOrderStatus = (orderId, status) => {
    setOrderState((prevOrders) => {
      // Map creates a new array; ensure the condition for update is correct
      const updatedOrders = prevOrders.map((order) =>
        order._id === orderId ? { ...order, status } : order
      );

      // Log the updated orders for debugging
      console.log('Updated Orders:', updatedOrders);

      return updatedOrders;
    });
  };

  const initializeSocket = () => {
    console.log('Initializing socket');
    if (socket.current) socket.current.disconnect();

    socket.current = io(host, {
      auth: {
        userInfo: {
          restaurantId: restaurantId,
          tableId: tableNumber,
        },
      },
    });

    socket.current.on('order-status-update', (tableOrder) => {
      console.log('Order status updated', tableOrder);
      updateOrderStatus(tableOrder.orderId, tableOrder.status);
    });

    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
    });
  };
  const fetchData = async () => {
    let data = {
      restaurantId: restaurantId || q_restaurantId,
      tableId: tableNumber || q_tableNumber,
    };

    const response = await generateGetCall({
      apiPath: `${ API_ENDPOINT_NAME.GET_ORDER }`,
      additionalData: data,
    });
    if (response.success) {
      setOrderState(response.response.data.orders);
      console.log(response.response.data.orders);
    }
  };

  useEffect(() => {
    fetchData();
    initializeSocket();
  }, [orderHistory]);

  return (
    <>
      <div className={styles.order_page_index_wrapper}>
        {!animationState && tableNumber && (
          <OrderHeader
            styles={styles}
            orderHistory={orderHistory}
            setOrderHistory={setOrderHistory}
            tableNumber={tableNumber}
            animationState={animationState}
          ></OrderHeader>
        )}
        {animationState ? (
          <AnimationComponent setAnimationState={setAnimationState} />
        ) : orderHistory ? (
          <OrderHistoryPage />
        ) : billingDetails ? (
          <>
            <BillingPage
              orderState={orderState}
              styles={styles}
              isEmployee={isEmployee}
              q_restaurantId={q_restaurantId}
              q_tableNumber={q_tableNumber}
              setTable={setTable}
              // billingDetails={billingDetails}
              orderStatus={orderState?.status}
            />
          </>
        ) : (
          <OrderPage
            orderState={orderState}
            styles={styles}
            isEmployee={isEmployee}
            q_restaurantId={q_restaurantId}
            q_tableNumber={q_tableNumber}
            setTable={setTable}
            // billingDetails={billingDetails}
            isUser={isUser}
          />
        )}
      </div>
    </>
  );
};

export default OrdersIndex;
