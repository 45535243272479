import React from 'react';
import styles from '../../styles/orderList/receipt.module.scss';

const order = {
  orderId: 'OD145',
  type: 'DineIn - Table 04',
  name: 'Robert Kipp',
  items: [
    {
      name: 'Chicken Biryani',
      price: 660,
      customised: ['cheese1'],
      isVeg: false,
      quantity: 1,
    },
    {
      name: 'Chicken',
      price: 260,
      customised: ['cheese1'],
      isVeg: false,
      quantity: 2,
    },
  ],
  date: '12/02/2023',
  time: '10:29 AM',
  instructions: 'Thank you for choosing Namaskar Restro.',
  price: 1180,
};

const Receipt = () => {
  return (
    <div className={styles.receipt_wrapper}>
      <header>
        <span>Namaskar Restro</span>
        <span>Sector 76, Noida</span>
        <span>
          {order.date} | {order.time}
        </span>
      </header>
      <div className={styles.order_info}>
        <span>Order ID: {order.orderId}</span>
        <span>{order.type}</span>
        <span>Customer: {order.name}</span>
      </div>
      <div className={styles.items_heading}>
        <span>Item</span>
        <span>Qty</span>
        <span>Price</span>
      </div>
      {order.items.map((item, index) => (
        <div className={styles.item_row} key={index}>
          <span>{item.name}</span>
          <span>{item.quantity}</span>
          <span>{item.price}</span>
        </div>
      ))}
      <div className={styles.total_section}>
        <span>Total</span>
        <span>{order.price}</span>
      </div>
      <footer>
        <span>{order.instructions}</span>
        <span>Thank you for visiting us!</span>
      </footer>
    </div>
  );
};

export default Receipt;
