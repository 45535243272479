const API_ENDPOINT_NAME = {
  // AUTH
  SIGNUP: '/auth/register',
  LOGIN: '/auth/login',
  OTPVERIFICATION: '/auth/verifyOTP',
  COUNTRY_PHONE_CODE:
    'https://gist.githubusercontent.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad/raw/2bc71083a77106afec2ec37cf49d05ee54be1a22/country_dial_info.json',
  RESEND: '/auth/resendOtp',
  RESTAURANT_PUBLIC_INFO: '/restaurant/publicInfo',
  RESTAURANT_TABLES_INFO: '/restaurant/getTable',
  RESTAURANT_PUBLIC_IMAGE: '/restaurant/download/images',
  RESTAURANT_TABLE_MAPPING_FROM_QR_ID: '/restaurant/getQRRestaurantMapping',

  // ADMIN FOOD
  ADD_ITEM: '/admin/addItem',
  DELETE_ITEM: '/admin/deleteItem',
  EDIT_ITEM: '/admin/editItem',
  UPLOAD_MENU: '/admin/restaurant/uploadmenu',

  //ORDER
  PLACE_ORDER: '/user/createOrder',
  GET_ORDER: '/user/getUserOrder',

  //CATEGORY
  GET_CATEGORY: '/user/category',
  GET_ADMIN_CATEGORY: '/admin/category',
  TEST: '/admin/deleteCategory',
  EDIT_CATEGORY: '/admin/editCategory',

  //SEARCH
  GET_SEARCH: '/user/search',

  //DISCOUNT
  GET_DISCOUNT: '/admin/offer',

  //RESERVATIONS
  GET_RESERVATIONS: '/user/reservations',
  ADD_RESERVATIONS: '/user/reservations',
  GET_PARTICULAR_RESERVATIONS: '/admin/reservation',

  // TABLES
  ADD_TABLE: '/admin/addTable',
  ADMIN_GET_TABLE: '/admin/getTable',
  GET_TABLE_LIST: '/user/tableList',

  // USER
  CREATE_CUSTOMER: '/user/createUser',
  GET_USER_ROLE: '/user/userRole',

  //CHECK_ADMIN
  CHECK_ADMIN: '/auth/authAdmin',

  //HISTORY
  HISTORY_RESERVATIONS: '/user/reservationsHistory',

  ENABLE_OR_DISABLE_DISH: '/admin/dishAvailability',

  //EMPLOYEE
  ADD_EMPLOYEE: '/admin/employee/addEmployee',
  GET_EMPLOYEE_DETAILS: '/admin/employee/getEmployeeDetails',
  GET_EMPLOYEES: '/admin/employee/getAllEmployees',
  LOCK_UNLOCK_EMPLOYEE: '/admin/employee/blockUnblockStatus',
  DELETE_EMPLOYEE: '/admin/employee/deleteEmployee',
  EDIT_EMPLOYEE: '/admin/employee/editEmployee',
  DOWNLOAD_EMPLYOEE_ASSET: '/admin/employee/downloadAsset',
  EMPLOYEE_ALL_ORDERS: '/admin/orders/getAllOrders',

  // EMPLOYEE WAIETR
  EMPLOYEE_SAVE_FCM_TOKEN: '/employee/save-fcm-token',
  ADMIN_SAVE_FCM_TOKEN: '/admin/save-fcm-token',
  EMPLOYEE_GET_MY_DETAILS: '/employee/getMyInfo',
  EMPLOYEE_WAITER_GET_TABLE_ORDERS:
    '/employee/waiter/getAssignedTablesAndOrders',
  EMPLOYEE_CHEF_GET_TABLE_ORDERS: '/employee/orders/activeOrders',

  // ORDER
  MARK_ORDER_AS_PAID: '/employee/order/markAsPaid',
  MARK_ORDER_AS_CANCEL: '/employee/order/markAsCancelled',
  UPDATE_ORDER_STATUS: '/employee/order/orderStatusUpdate',
};

let SOCKET_HOST = 'http://192.168.0.248:5001';

const hostname = window.location.hostname;
if (
  hostname === 'localhost' ||
  hostname === '127.0.0.1' ||
  hostname === '192.168.0.241'
) {
  SOCKET_HOST = 'http://localhost:5001'; // Local development endpoint  //baseURL = 'http://192.168.0.248:5001/api/v1'; // Local development endpoint
} else if (hostname === 'app-dev.easebite.in') {
  SOCKET_HOST = 'https://api-dev.easebite.in'; // Development endpoint
} else if (hostname === 'app.easebite.in') {
  SOCKET_HOST = 'https://api.easebite.in'; // Production endpoint
}


const USER_ROLES = {
  EASEBITE_SUPER_ADMIN: 'EASEBITE_SUPER_ADMIN',
  RESTAURANT_ADMIN: 'RESTAURANT_ADMIN',
  RESTAURANT_EMPLOYEE: 'RESTAURANT_EMPLOYEE',
  EASEBITE_USER: 'EASEBITE_USER',
};

export { API_ENDPOINT_NAME, USER_ROLES, SOCKET_HOST };
