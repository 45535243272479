import axios from 'axios';

// Extract the hostname from the window.location object
const hostname = window.location.hostname;

let host = 'http://localhost:5001';
// Default API endpoint
let baseURL = 'http://localhost:5001/api/v1'; // Default endpoint

// Check if the app is running on localhost
if (
  hostname === 'localhost' ||
  hostname === '127.0.0.1' ||
  hostname === '192.168.0.241'
) {
  baseURL = 'http://localhost:5001/api/v1'; // Local development endpoint
  //baseURL = 'http://192.168.0.248:5001/api/v1'; // Local development endpoint
  // baseURL = 'http://192.168.0.241:5001/api/v1'; // Local development endpoint
  // baseURL = 'http://192.168.0.179:5001/api/v1'; // Local development endpoint
}
// Check if the app is running on app-dev.easebite.in
else if (hostname === 'app-dev.easebite.in') {
  baseURL = 'https://api-dev.easebite.in/api/v1'; // Development endpoint
}
// Check if the app is running on app.easebite.in
else if (hostname === 'app.easebite.in') {
  baseURL = 'https://api.easebite.in/api/v1'; // Production endpoint
}

const instance = axios.create({
  baseURL: baseURL,
});

// export { host };

export default instance;
