import React, { useEffect, useState } from 'react';
import styles from '../../styles/admin/AdminTables.module.scss';

import SearchComponent from '../../components/searchComponent/SearchComponent';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { ReactComponent as SixSitter } from '../../assets/components/tablesIcon/sixSitter.svg';
import { ReactComponent as FourSitter } from '../../assets/components/tablesIcon/fourSitter.svg';
import TableNumberList from './TableNumberList';
import OrdersIndex from '../orderPage/OrdersIndex';
import { getLocalStorageItem } from '../../utils/generalFunction';
import { useNavigate } from 'react-router-dom';
import { generateGetCall } from '../../api_calls/generateGetCall';
import addIcon from '../../assets/admin/addIcon.svg';

const AdminTables = () => {
  const [table, setTable] = useState();
  const [sideBar, setSideBar] = useState();
  const [searchInput, setSearchInput] = useState();
  const [historyTable, sethistoryTable] = useState(false);
  const [addItem, setAdditem] = useState(false);
  const [tablesData, setTablesData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [handRaisedTables, setHandRaisedTables] = useState([
    '66f932cf8629221a602add03',
  ]);
  const [billingDetails, setBillingDetails] = useState(false);

  const navigate = useNavigate();
  const fetchTablesData = async () => {
    try {
      const response = await generateGetCall({
        apiPath: API_ENDPOINT_NAME.ADMIN_GET_TABLE,
      });
      return response;
    } catch (error) {
      console.error('Error fetching tables:', error);
      // throw error;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTablesData();
        if (response.success) {
          const tablesData = response.response.data.data;
          const tablesWithDataAndIcons = tablesData.map((table) => {
            if (table.capacity < 4) {
              return {
                ...table,
                icon: <FourSitter className={styles.primaryColorStroke} />,
              };
            } else {
              return {
                ...table,
                icon: <SixSitter className={styles.primaryColorStroke} />,
              };
            }
          });
          setTablesData(tablesWithDataAndIcons);

          return tablesWithDataAndIcons;
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching tables data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div
        className={
          styles.main_container_admin_tables + ' ' + styles.adminTablesPage_main
        }
      >
        {!selectedOrder && <SearchComponent setSearchInput={setSearchInput} />}
        {!table ? (
          <>
            <header className={styles.tablePageheader}>
              <h3>Tables</h3>
              <span>
                {/* <span onClick={() => sethistoryTable(true)}>
               <img src={history} alt="history" />
               History
             </span> */}
                <div className={styles.AddItem_button}>
                  <button onClick={() => setAdditem(true)}>
                    Add Table
                    <img src={addIcon} alt="addIcon" />
                  </button>
                </div>
              </span>
            </header>
            <TableNumberList
              setTable={setTable}
              data={tablesData}
              historyTable={historyTable}
              handRaisedTables={handRaisedTables}
              setHandRaisedTables={setHandRaisedTables}
              setSelectedOrder={setSelectedOrder}
              defaulHandVisible={false}
            />
          </>
        ) : (
          <>
            <div className={styles.ChefOrderPage_button_wrapper}>
              <button
                className={!billingDetails ? styles.activeBtn : null}
                onClick={() => setBillingDetails(false)}
              >
                Order Details
              </button>
              <button
                className={billingDetails ? styles.activeBtn : null}
                onClick={() => setBillingDetails(true)}
              >
                Bill Details
              </button>
            </div>

            {billingDetails ? (
              <OrdersIndex
                isEmployee={true}
                q_restaurantId={getLocalStorageItem('restaurantId')}
                q_tableNumber={table._id}
                setTable={setTable}
                billingDetails={billingDetails}
              />
            ) : (
              <OrdersIndex
                isEmployee={true}
                q_restaurantId={getLocalStorageItem('restaurantId')}
                q_tableNumber={table._id}
                setTable={setTable}
                isUser={false}
              />
            )}
          </>
        )}
      </div>

      {addItem ? (
        <AddTablePopper
          tablesData={tablesData}
          setTablesData={setTablesData}
          setAdditem={setAdditem}
        />
      ) : null}
    </>
  );
};

export default AdminTables;

const AddTablePopper = ({ setTablesData, setAdditem }) => {
  const [capacity, setCapacity] = useState();
  const [tableNumber, setTableNumber] = useState();
  const [error, setError] = useState();

  const handleBack = () => {
    setAdditem(false);
  };

  const handleSubmit = async () => {
    const data = {
      capacity: capacity,
      tableNumber,
    };
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.ADD_TABLE,
      data,
    });
    if (response?.success) {
      const table = response.response.data.data;
      table.icon =
        table?.capacity < 4 ? (
          <FourSitter className={styles.primaryColorStroke} />
        ) : (
          <SixSitter className={styles.primaryColorStroke} />
        );
      setTablesData((tables) => [...tables, table]);
      setAdditem(false);
    } else {
      setError(response);
    }
  };

  const editError = (key) => {
    if (error?.key === key || error?.key === key) {
      setError(null);
    }
  };

  return (
    <div className={styles.add_new_table_wrapper}>
      <div className={styles.inner_wrapper}>
        <header>
          {/* <img onClick={handleBack} src={backArrow} alt="back" /> */}
          <BackArrow onClick={handleBack} className={styles.primaryColor} />
          Add Table
        </header>
        <div>
          <div className={styles.input_wrapper}>
            <input
              type="text"
              style={
                error?.key === 'tableNumber'
                  ? { border: '1px solid red' }
                  : null
              }
              placeholder="Table No. "
              value={tableNumber}
              onClick={() => editError('tableNumber')}
              onChange={(e) => setTableNumber(e.target.value)} // Update price state on input change
            />
            <input
              style={
                error?.key === 'capacity' ? { border: '1px solid red' } : null
              }
              type="text"
              placeholder="Capacity"
              value={capacity}
              onClick={() => editError('capacity')}
              onChange={(e) => setCapacity(e.target.value)} // Update numSeats state on input change
            />
          </div>
          <span>{error ? `${ error.message }` : ' '}</span>
          <div className={styles.button_wrapper}>
            <button onClick={handleBack}>Cancel</button>
            <button onClick={handleSubmit}>Add Table</button>
          </div>
        </div>
      </div>
    </div>
  );
};
